import { searchConstants } from '../constants/search.constants';

const initialState = {};

export function search(state = initialState, action) {

  switch (action.type) {

    case searchConstants.SEARCH_ONE_REQUEST:      
      return {
        ...state,
        executingSearchOne: true,  
        searchOneResults: null      
      };
    case searchConstants.SEARCH_ONE_SUCCESS:      

      let searchOneResults = [];

      action.response.body.results.map((item, index) => {      
        
        const recordFields = item.record.split(";");
        const text = recordFields[3];// + " (" + recordFields[2] + ")"; 

        searchOneResults.push({ 
          key: index,        
          value: item.record,
          text: text
        });
      })

      return {
        ...state,
        executingSearchOne: false,
        searchOneResults: searchOneResults
      };

    case searchConstants.SEARCH_ONE_FAILURE:      
      return {
        ...state,
        executingSearchOne: false,
        searchOneResults: null
      };
    case searchConstants.MATCH_REQUEST:      
      return {
        ...state,
        executingMatchRecord: true,  
        results: []      
      };
    case searchConstants.MATCH_SUCCESS:      

      let matchResults = [];
      //console.log("action.response: ",action.response);
      if (action.response) {
        action.response.results.map((item, index) => {

          const record = item.record.split("\t");
          //console.log("record",record);

          const score = parseFloat(item.score).toFixed(2);
          const dob = record[2] ? record[2].substring(0, 4) + "-" + record[2].substring(4, 6) + "-" + record[2].substring(6) : "";
          let address = record[6] + ', ' + record[7] + ', ' + record[3] + ', ' + record[4] + ', ' + record[5];
          address = address.replace(/(^[,\s]+)|([,\s]+$)/g, '');
          let phoneList = record[15] ? record[15].replace(/(^[,\s]+)|([,\s]+$)/g, '') : ''
          phoneList = phoneList.replace(/,/g, ', ');
          const fullName =  record[16] + ' ' + (record[17] ? record[17] + ' ' : "") + record[18];   
          let aliasNamesList = record[25] ? record[25].replace(/(^[,\s]+)|([,\s]+$)/g, '') : ''
          aliasNamesList = aliasNamesList.replace(/,/g, ', ');     

          matchResults.push({
            key:  index,
            type: item.type,
            pass: item.pass,
            score: score,
            IQ_PK: record[0],              
            CUSTOMER_ID: record[1],              
            DOB: dob,           
            SUBURB: record[3],             
            CITY: record[4],             
            POSTCODE: record[5], 
            NumberRange: record[6],            
            STREETNAME: record[7], 
            ADDRESS: address,       
            DPID: record[8],              
            BSP: record[9],              
            STREETSOUNDEX: record[10],              
            STREETRSOUNDEX: record[11],              
            LOCALITYSOUNDEX: record[12],              
            USERNAME: record[13],              
            EMAILADDRESS: record[14],              
            phoneList: phoneList,              
            FIRSTNAME: record[16],              
            MIDDLENAME: record[17],              
            LASTNAME: record[18],
            FULLNAME: fullName,
            NYSIISFIRST: record[19],              
            NYSIISLAST: record[20],              
            NamesList: record[21],               
            NysiisList: record[22],              
            COMMONALIAS: record[23],             
            COMMONALIASNYSIIS: record[24],              
            ALIASNAMESLIST: aliasNamesList
          });
        })
      }

      return {
        ...state,
        executingMatchRecord: false,
        results: matchResults
      };

    case searchConstants.MATCH_FAILURE:      
      return {
        ...state,
        executingMatchRecord: false,
        results: []
      };
    default:
      return state
  }
}