import React from 'react';
import ReactDOM from 'react-dom';
//import './index.css';
import 'antd/dist/antd.css'
import App from './App';
import * as serviceWorker from './serviceWorker';
import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import { Provider } from 'react-redux';
import { search } from './reducers/search.reducer';

const rootReducer = combineReducers({  
  search
});
const store = createStore(rootReducer, applyMiddleware(thunk));

const app = (
    <Provider store={store}>        
        <App />        
    </Provider>
);

ReactDOM.render(app, document.getElementById('root'));
serviceWorker.unregister();

