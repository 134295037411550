import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Table, Divider, Tag, Radio } from 'antd';

class TransactionList extends Component {

    constructor(props) {
        super(props)        
    }

    render() {       

        const columns = [            
            {
              title: 'Score',
              dataIndex: 'score',
              key: 'score',
            },
            {
              title: 'Customer Id',
              key: 'CUSTOMER_ID',
              dataIndex: 'CUSTOMER_ID'
            },
            {
              title: 'Full Name',
              key: 'FULLNAME',
              dataIndex: 'FULLNAME'
            },            
            {
              title: 'D.O.B',
              key: 'DOB',
              dataIndex: 'DOB',
              className: 'noWrap'
            },
            {
              title: 'Address',
              key: 'ADDRESS',
              dataIndex: 'ADDRESS'
            },
            {
              title: 'Email',
              key: 'EMAILADDRESS',
              dataIndex: 'EMAILADDRESS'
            },           
            {
              title: 'Phone(s)',
              key: 'phoneList',
              dataIndex: 'phoneList'
            },
            {
              title: 'Common Alias',
              key: 'COMMONALIAS',
              dataIndex: 'COMMONALIAS'
            },
            {
              title: 'Alias Names List',
              key: 'ALIASNAMESLIST',
              dataIndex: 'ALIASNAMESLIST'
            }                 
          ];

        return (
            <div style={{ height: '100%' }} >                
              <Table 
                id="resultsTable"
                columns={columns} 
                dataSource={this.props.results}                
                size="small"
                loading={this.props.executingMatchRecord}                
                style={{ width: '100%', height: '100%' }}  
                pagination={false}
              />
            </div>           
        );
    }

}

function mapStateToProps(state) {
  const { executingMatchRecord, results } = state.search;
  return {
      executingMatchRecord,
      results
  };
}

const connectedTransList = connect(mapStateToProps)(TransactionList);
export { connectedTransList as TransactionList }; 

