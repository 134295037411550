export const searchConstants = {

    SEARCH_ONE_REQUEST: 'SEARCH_ONE_REQUEST',
    SEARCH_ONE_SUCCESS: 'SEARCH_ONE_SUCCESS',
    SEARCH_ONE_FAILURE: 'SEARCH_ONE_FAILURE',  
        
    MATCH_REQUEST: 'MATCH_REQUEST',
    MATCH_SUCCESS: 'MATCH_SUCCESS',
    MATCH_FAILURE: 'MATCH_FAILURE'  
};
