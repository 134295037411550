import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Form,
  Input,  
  Row,
  Col,
  Button,
  AutoComplete,
  Spin,
  Modal
} from 'antd';

import { searchActions } from '../../actions/search.actions';
import { isArray } from 'util';

class EntitySearch extends Component {

  constructor(props) {

    super(props)

    this.state = {
      inLOAD_PK: '',
      inCUSTOMER_ID: '',
      inFullNAME: '',
      inDOB: '',
      inADDRESS: '',
      inPHONE1: '',
      inPHONE2: '',
      inFAX: '',
      inMOBILE: '',
      inEMAILADDRESS: '',
      blockNameDatasource: [],
      selectedBlockName: null
    }

    this.handleChange = this.handleChange.bind(this);
    this.handleBlockNameChange = this.handleBlockNameChange.bind(this);
    this.handleBlockNameSelect = this.handleBlockNameSelect.bind(this);
    this.handleBlockNameSearch = this.handleBlockNameSearch.bind(this);
    this.handleBlockNameSelect = this.handleBlockNameSelect.bind(this);
    this.showBlockDetails = this.showBlockDetails.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  }

  handleBlockNameChange(value) {
    this.setState({ blockName: value });    
  }  

  handleBlockNameSearch() {

    this.setState({selectedBlock: null});

    const referenceData = "NZP_Firearms";

    const {
      blockName
    } = this.state;   

    const { dispatch } = this.props;

    dispatch(searchActions.searchOne(referenceData, blockName));    
  }

  handleBlockNameSelect(value) {   
    
    const recordFields = value.split(";");
    let selectedBlock = null;    

    if (isArray(recordFields) && recordFields.length > 11) {
//record_Id;category;sub_category;aliase_name;manufacturer;model;caliber;cartridge;action_type;action_sub_type;mag_capacity;barrel_length;stock_type
      selectedBlock = {
        record_Id: recordFields[0],
        category: recordFields[1],
        sub_category: recordFields[2],
        alias_name: recordFields[3],
        manufacturer: recordFields[4],
        model: recordFields[5],
        caliber: recordFields[6],
        cartridge: recordFields[7],
        action_type: recordFields[8],
        action_sub_type: recordFields[9],
        mag_capacity: recordFields[10],
        barrel_length: recordFields[11],
        stock_type: recordFields[12]
      };
    }

    this.setState({selectedBlock: selectedBlock});
  }

  showBlockDetails() {
    
    if (!this.state.selectedBlock) {
      return;
    }

    Modal.info({
      title: 'Firearm Information',
      width: "500px",
      content: (        
        <div> 
          <br />
          <Row>
            <Col span={7}><strong>Record Id</strong></Col>
            <Col span={17}><span>{this.state.selectedBlock.record_Id}</span></Col>
          </Row> 
          <Row>
            <Col span={7}><strong>Category</strong></Col>
            <Col span={17}><span>{this.state.selectedBlock.category}</span></Col>
          </Row> 
          <Row>
            <Col span={7}><strong>Sub Category</strong></Col>
            <Col span={17}><span>{this.state.selectedBlock.sub_category}</span></Col>
          </Row> 
          <Row>
            <Col span={7}><strong>Alias Name</strong></Col>
            <Col span={17}><span>{this.state.selectedBlock.alias_name}</span></Col>
          </Row> 
          <Row>
            <Col span={7}><strong>Manufacturer</strong></Col>
            <Col span={17}><span>{this.state.selectedBlock.manufacturer}</span></Col>
          </Row> 
          <Row>
            <Col span={7}><strong>Model</strong></Col>
            <Col span={17}><span>{this.state.selectedBlock.model}</span></Col>
          </Row> 
          <Row>
            <Col span={7}><strong>Caliber</strong></Col>
            <Col span={17}><span>{this.state.selectedBlock.caliber}</span></Col>
          </Row> 
          <Row>
            <Col span={7}><strong>Cartridge</strong></Col>
            <Col span={17}><span>{this.state.selectedBlock.cartridge}</span></Col>
          </Row> 
          <Row>
            <Col span={7}><strong>Action Type</strong></Col>
            <Col span={17}><span>{this.state.selectedBlock.action_type}</span></Col>
          </Row> 
          <Row>
            <Col span={7}><strong>Action Sub Type</strong></Col>
            <Col span={17}><span>{this.state.selectedBlock.action_sub_type}</span></Col>
          </Row> 
          <Row>
            <Col span={7}><strong>Mag Capacity</strong></Col>
            <Col span={17}><span>{this.state.selectedBlock.mag_capacity}</span></Col>
          </Row> 
          <Row>
            <Col span={7}><strong>Barrel Length</strong></Col>
            <Col span={17}><span>{this.state.selectedBlock.barrel_length}</span></Col>
          </Row>   
          <Row>
            <Col span={7}><strong>Stock Type</strong></Col>
            <Col span={17}><span>{this.state.selectedBlock.stock_type}</span></Col>
          </Row>       
        </div>
      ),
      onOk() { },
    });
  }

  handleSubmit(e) {

    e.preventDefault();

    const matchSpec = "DemoMatch3.iqm"

    const {
      inLOAD_PK,
      inCUSTOMER_ID,
      inFullNAME,
      inDOB,
      inADDRESS,
      inPHONE1,
      inPHONE2,
      inFAX,
      inMOBILE,
      inEMAILADDRESS
    } = this.state;

    const record = inLOAD_PK + ';' + inCUSTOMER_ID + ";" + inFullNAME + ";" + inDOB + ";" + inADDRESS + ";" +
      inPHONE1 + ";" + inPHONE2 + ";" + inFAX + ";" + inMOBILE + ";" + inEMAILADDRESS
      //;1;2;3;4;;;;6;7;
    // console.log("record", record);

    const { dispatch } = this.props;

    if (matchSpec && record) {
      dispatch(searchActions.matchRecord(matchSpec, record));
    }
  }

  render() {

    return (
      <div className="myClass">
        <Row>
          <Col xl={12} lg={18} md={22} sm={24} xs={24}>
            <Form name="form">
              <Row>
                <Col span={5}>
                  <label htmlFor="inputCustomerId">Customer Id</label>
                </Col>
                <Col span={14}>
                  <Input
                    id="inputCustomerId"
                    type="text"
                    className="form-control"
                    name="inCUSTOMER_ID"
                    placeholder="Customer ID"
                    value={this.state.inCUSTOMER_ID}
                    onChange={this.handleChange}
                  />
                </Col>
              </Row>
              <Row>
                <Col span={5}>
                  <label htmlFor="inputFullName">Name</label>
                </Col>
                <Col span={14}>
                  <Input
                    id="inputFullName"
                    type="text"
                    className="form-control"
                    name="inFullNAME"
                    placeholder="Full Name"
                    value={this.state.inFullNAME}
                    onChange={this.handleChange}
                  />
                </Col>
              </Row>
              <Row>
                <Col span={5}>
                  <label htmlFor="inputDob">D.O.B</label>
                </Col>
                <Col span={14}>
                  <Input
                    id="inputDob"
                    type="text"
                    className="form-control"
                    name="inDOB"
                    placeholder="Date of Birth (yyyy-mm-dd)"
                    value={this.state.inDOB}
                    onChange={this.handleChange}
                  />
                </Col>
              </Row>
              <Row>
                <Col span={5}>
                  <label htmlFor="inputAddress">Address</label>
                </Col>
                <Col span={14}>
                  <Input
                    id="inputAddress"
                    type="text"
                    className="form-control"
                    name="inADDRESS"
                    placeholder="Address"
                    value={this.state.inADDRESS}
                    onChange={this.handleChange}
                  />
                </Col>
              </Row>
              <Row gutter={4}>
                <Col span={5}>
                  <label htmlFor="inputPhone">Phone/Mobile</label>
                </Col>
                <Col span={7}>
                  <Input
                    id="inputPhone"
                    type="text"
                    className="form-control"
                    name="inPHONE1"
                    placeholder="Phone"
                    value={this.state.inPHONE1}
                    onChange={this.handleChange}
                  />
                </Col>
                <Col span={7}>
                  <Input
                    type="text"
                    className="form-control"
                    name="inMOBILE"
                    placeholder="Mobile"
                    value={this.state.inMOBILE}
                    onChange={this.handleChange}
                  />
                </Col>
              </Row>
              <Row>
                <Col span={5}>
                  <label htmlFor="inputEmail">Email</label>
                </Col>
                <Col span={14}>
                  <Input
                    id="inputEmail"
                    type="text"
                    className="form-control"
                    name="inEMAILADDRESS"
                    placeholder="Email Address"
                    value={this.state.inEMAILADDRESS}
                    onChange={this.handleChange}
                  />
                </Col>
              </Row>
              <Row>
                <Col span={5}>
                  <label htmlFor="searchBlockName">Firearm Name</label>
                </Col>
                <Col span={14}>
                  <AutoComplete
                    id="searchBlockName"
                    value={this.state.blockName}
                    dataSource={this.props.searchOneResults}
                    //style={{ width: 200 }}
                    onSelect={this.handleBlockNameSelect}
                    onSearch={this.handleBlockNameSearch}
                    onChange={this.handleBlockNameChange}
                    onSelect={this.handleBlockNameSelect}
                    placeholder="Firearm Name Search"
                    notFoundContent={this.props.executingSearchOne ? <Spin /> : "Not Found"}
                  />
                </Col>
                <Col span={1}>
                  <Button type="link" size="large" onClick={this.showBlockDetails}>...</Button>
                </Col>
              </Row>
              <Row>
                <Col span={19} className="submitButton">
                  <Button type="primary" onClick={this.handleSubmit} >Search</Button>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const {
    executingMatchRecord,
    executingSearchOne,
    searchOneResults
  } = state.search;
  return {
    executingMatchRecord,
    executingSearchOne,
    searchOneResults

  };
}

// const connectedEntitySearch = Form.create({ name: 'Search' })(connect(mapStateToProps)(EntitySearch));
const connectedEntitySearch = connect(mapStateToProps)(EntitySearch);
export { connectedEntitySearch as EntitySearch };

