import { searchConstants } from '../constants/search.constants';
import { searchService } from '../services/search.service';
import { alertActions } from './alert.actions';

export const searchActions = {
    searchOne,
    matchRecord
};

function searchOne(referenceData, searchString) {
    return dispatch => {
        dispatch(request({}));

        searchService.searchOne(referenceData, searchString)
            .then(
                response => {                    
                    dispatch(success(response));                    
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(response) { return { type: searchConstants.SEARCH_ONE_REQUEST, response } }
    function success(response) { return { type: searchConstants.SEARCH_ONE_SUCCESS, response } }
    function failure(error) { return { type: searchConstants.SEARCH_ONE_FAILURE, error } }
}

function matchRecord(matchSpecs, record) {
    return dispatch => {
        dispatch(request({}));

        searchService.matchRecord(matchSpecs, record)
            .then(
                response => {                    
                    dispatch(success(response));                    
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(response) { return { type: searchConstants.MATCH_REQUEST, response } }
    function success(response) { return { type: searchConstants.MATCH_SUCCESS, response } }
    function failure(error) { return { type: searchConstants.MATCH_FAILURE, error } }
}



