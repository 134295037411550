import React from 'react';

import { EntitySearch } from '../EntitySearch/EntitySearch';
import { TransactionList } from '../TransactionList/TransactionList';

class HomePage extends React.Component {      
    
    render() { 
                
        return (
            <div style={{ background: '#fff'}}> 
                <div style={{ textAlign: 'center'}}>
                    <h2>Intech Solutions - Search Demo</h2> 
                </div> 
                <br />             
                <EntitySearch />                                
                <TransactionList />  
            </div>
        );
    }
    
}

export default HomePage;