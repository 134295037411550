import {  config } from '../helpers/config';

export const searchService = {
    searchOne,
    matchRecord
};

function searchOne(referenceData, searchString) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ referenceData, searchString })
    };

    return fetch(config.apiUrl + '/api/search', requestOptions).then(handleResponse, handleError)        
}

function matchRecord(matchSpec, record) {
    const requestOptions = {
        method: 'GET',//'POST',
        headers: { 'Content-Type': 'application/json' },
        //body: JSON.stringify({ matchSpec, record })
    };

    return fetch(config.matchUrl + '/matcher?matchSpec='+matchSpec+'&inputRecord='+record, requestOptions).then(handleResponse, handleError)        
}

function handleResponse(response) {
    return new Promise((resolve, reject) => {
        if (response.ok) {
            // return json if it was returned in the response
            var contentType = response.headers.get("content-type");
            if (contentType && contentType.includes("application/json")) {
                response.json().then(json => resolve(json));
            } else {
                resolve();
            }
        } else {
            // return error message from response body
            response.text().then(text => reject(text));
        }
    });
}

function handleError(error) {
    return Promise.reject(error && error.message);
}